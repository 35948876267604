import DiscordLogo from '$images/discord.svg';
import GalxeLogo from '$images/Galxe.svg';
import GitbookLogo from '$images/gitbook.svg';
import TwitterLogo from '$images/twitter.svg';
import ZealyLogo from '$images/zealy.svg';

export enum SocialEnum {
  TWITTER,
  DISCORD,
  ZEALY,
  GITBOOK,
  GALXE,
}

export interface ISocials {
  type: SocialEnum;
  label: string;
  link: string;
  icon: string;
}

export const socials: ISocials[] = [
  {
    type: SocialEnum.TWITTER,
    label: 'Twitter',
    link: 'https://twitter.com/Hana_Finance',
    icon: TwitterLogo,
  },
  {
    type: SocialEnum.DISCORD,
    label: 'Discord',
    link: 'https://discord.gg/hanafinance',
    icon: DiscordLogo,
  },
  {
    type: SocialEnum.GALXE,
    label: 'Galxe',
    link: 'https://galxe.com/HanaFinance',
    icon: GalxeLogo,
  },
  // {
  //   type: SocialEnum.ZEALY,
  //   label: 'Zealy',
  //   link: 'https://zealy.io/c/hanafinance',
  //   icon: ZealyLogo,
  // },
  // {
  //   type: SocialEnum.GITBOOK,
  //   label: 'Gitbook',
  //   link: 'https://hanafinance.gitbook.io/hanafinance',
  //   icon: GitbookLogo,
  // },
];
